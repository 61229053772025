<template>
  <div class="auth-container">
    <div class="auth-content-container">
      <div class="auth-content">
        <div class="logo"></div>
        <div class="title">
          Sign In to Admin panel
        </div>
        <div class="error-container" v-if="errorMessage">
          {{this.errorMessage}}
        </div>
        <div class="input-container">
          <div class="field-label">Email</div>
          <input class="input-field" v-model="email">
        </div>
        <!-- Password field -->
        <div class="input-container password-container">
          <div class="field-label">Password</div>
          <input
            :type="passwordFieldType"
            class="input-field"
            v-model="password"
            @keyup.enter="handleAuth"
          />
          <div
            class="password-visibility-btn"
            :class="{
              'password-visible-icon': passwordFieldType === passwordFieldTypes.PASSWORD,
              'password-invisible-icon': passwordFieldType === passwordFieldTypes.TEXT,
            }"
            @click="switchPasswordVisibility"
          >
          </div>
        </div>
        <div
          class="reset-password-btn"
          @click='handelResetPassword'
          >
          Reset Password
        </div>
        <ActionButton
          v-if="!isLoading"
          :width="'100%'"
          :height="'54px'"
          :minHeight="'54px'"
          :text="'Next'"
          @click="handleAuth"
        />
        <div class="action-btn loading-indicator-container" v-if='isLoading'>
          <div class="spinner"></div>
        </div>
        <div class="spacer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState} from 'vuex'

import ActionButton from '../../common/ActionButton.vue'

import { UserRole } from '../../../utils/constants'

export default {
  components: {
    ActionButton,
  },
  data() {
    return {
      modes: {
        SIGN_UP: 'SIGN_UP',
        SIGN_IN: 'SIGN_IN',
      },
      passwordFieldTypes: {
        PASSWORD: 'password',
        TEXT: 'text',
      },
      currentMode: '',
      passwordFieldType: '',
      fullName: '',
      email: '',
      password: '',
      isLoading: false,
      errorMessage: '',
    }
  },
  computed: {
    ...mapState([
      'currentUser'
    ])
  },
  created() {
    this.setCurrentMode(this.$route.name)
  },
  methods: {
    ...mapActions([
      'AdminsignIn',
    ]),
    handleError(error) {
      if (error.response.status === 403) {
        this.$router.push({ name: 'SignIn' })
        return
      }

      if (this.currentMode === this.modes.SIGN_UP) {
        if (error.response.status === 400) {
          this.errorMessage = 'This email address has already been used. Please sign in, or select "forgot password"'
          return
        }
      }
      if (error.response.status === 400) {
        this.errorMessage = 'Email or password is not correct'
        return
      }
    },
    setCurrentMode(){
      this.passwordFieldType = this.passwordFieldTypes.PASSWORD
    },
    switchPasswordVisibility() {
      if (this.passwordFieldType === this.passwordFieldTypes.PASSWORD) {
        this.passwordFieldType = this.passwordFieldTypes.TEXT
      } else {
        this.passwordFieldType = this.passwordFieldTypes.PASSWORD
      }
    },
    handelResetPassword() {
      this.$router.push({ 'name': 'ForgotPasswordAdmin' })
    },
    validateEmail() {
      const emailPattern = new RegExp(/^.+@.+\..+$/);
      return emailPattern.test(this.email);
    },
    validateCredentials() {
      if (!this.validateEmail()) {
        this.errorMessage = 'Please enter the valid email'
        return false
      }
      return true
    },
    async handleAuth() {
      const isValidCredentials = this.validateCredentials()

      if(!isValidCredentials) return

      this.isLoading = true
      const data = {
        user: {
          email: this.email,
          password: this.password,
        }
      }

      try {
        await this.AdminsignIn(data)

        if (this.currentUser.role === UserRole.Admin) this.$router.push({name:'AdminContent'})
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../../assets/styleVars.scss';

  .auth-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    .auth-content-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      overflow-y: scroll;

      .auth-content {
        width: 345px;
        height: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        color: $fontColorBlack;

        .logo {
          width: 100%;
          height: 200px;
          min-height: 200px;
          background-image: url('../../../assets/images/simple_sales_logo.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
        }
        .title {
          margin-top: 20px;
          font-size: $fontSizeTitle;
          font-weight: $bolderFont;
        }
        .error-container {
          width: 100%;
          margin-top: 10px;
          color:$redWarningColor;
          font-size: $fontSizeMinified;
        }

        .field-label {
          color: $fontColorGray;
          font-size: $fontSizeMinified;
        }

        .input-field {
          width: 100%;
          height: 44px;
          margin-top: 8px;
          padding: 0 6px;
          font-size: $fontSizeStandart;
          border: 1px solid $grayBorderColor;
          box-sizing: border-box;
          border-radius: 4px;
        }

        .input-container {
          width: 100%;
        }

        & > .input-container {
          margin-top: 30px;
        }

        & > .input-container ~ .input-container {
          margin-top: 20px;
        }

        .password-container {
          position: relative;

          .password-visibility-btn {
            width: 24px;
            height: 24px;
            position: absolute;
            top: calc(100% - 34px);
            right: 12px;
            height: 24px;
            background-size: 24px;
            background-position: center;
            cursor: pointer;
          }

          .password-invisible-icon {
            background-image: url('../../../assets/icons/visibility-off-icon.svg');
          }

          .password-visible-icon {
            background-image: url('../../../assets/icons/visibility-on-icon.svg');
          }
        }
        .reset-password-btn {
          margin-top: 20px;
          color: $fontColorBlue;
          cursor: pointer;

          &:hover {
            color: $fontColorBlueHovered;
          }
        }
        .action-btn {
          width: 100%;
          height: 54px;
          min-height: 54px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $appActionColor;
          color: $fontColorWhite;
          margin-top: 20px;
          cursor: pointer;

          &:hover {
            background-color: $appActionColorHovered;
          }
        }

        .loading-indicator-container {
          cursor: wait;

          &:hover {
            background-color: $appActionColor;
          }

          .spinner {
            border: 5px solid $whiteColor;
            border-radius: 50%;
            border-top: 5px solid $appActionColor;
            width: 20px;
            height: 20px;
            animation: spin 2s linear infinite;
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        }
      }
    }
  }
</style>
